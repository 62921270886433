import React, { memo } from 'react'
import styled, { keyframes } from 'styled-components'

const AnimateText = ({ children }) => {
	if (typeof children !== 'string') {
		return children
	}

	const arr = children.split(' ')

	return arr.map((word, index) => (
		<WordContainer key={index}>
			<Word style={{ animationDelay: `${index * 100}ms` }}>
				{word + ' '}
			</Word>
		</WordContainer>
	))
}

const transform = keyframes`
  from { transform: translateY(100%) }
  to   { transform: translateY(0) }
`

const WordContainer = styled.div`
	display: inline-block;
	padding-bottom: 1%;
	padding-top: 1%;
	overflow: hidden;
`

const Word = styled.span`
	display: inline-block;
	transform: translateY(100%);
	animation: ${transform} 0.8s normal forwards
		cubic-bezier(0.075, 0.82, 0.165, 1);
	animation-iteration-count: 1;

	&:after {
		display: inline-block;
		content: ' ';
	}
`

export default memo(AnimateText)
